import { theme } from '@vfs-digital-channels/ns-react-components';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import {
  useRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import {
  AddressDetailsComponent,
  ErrorModalSection,
  FormSubmitHelper,
  SupportPopup,
  addressValidationSchema
} from '../../../components';
import {
  FormContainer,
  FormFieldWrapper,
  Provinces,
  submissionStates
} from '../../../constants';
import {
  CountryListInput,
  mapValues,
  replaceUndefined,
  stripEmpty
} from '../../../helpers';
import { businessDetailsSubmit } from '../../../recoil';
import styles from './BusinessAddressDetails.module.css';

import { useModal } from '../../../components/modalController/useModal';
import { findFirstValidationError } from '../../../helpers/formik';
import { useTealium } from '../../../tealium/useTealium';

const validationSchema = yup.object({
  address: addressValidationSchema
});

export const BusinessAddressDetails = () => {

  const [openModal, closeModal] = useModal();
  const { submitState, setSubmitState } = useOutletContext();
  const [details, setDetails] = useRecoilState(businessDetailsSubmit);
  const formRef = React.useRef();
  const { track, events } = useTealium();
  const location = useLocation();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.businessAddress.formStart());
  };

  useEffect(() => {
    track(events.businessAddress.pageView());
  }, []);

  const formDetails = useMemo(() => replaceUndefined({
    ...details,
    address: details.address && {
      ...details.address,
      province: Provinces.find((p) => p.value === details.address.province),
      country: CountryListInput.find((c) => c.value === 'ZA')
    }
  }), [details]);

  const formatFormData = (formData) => mapValues(stripEmpty({
    ...formData,
    address: formData.address && {
      ...formData.address,
      country: formData.address.country?.label ?? CountryListInput.find((c) => c.value === 'ZA')?.label
    }
  }));

  const submitHandler = (formData) => {
    track(events.businessAddress.formEnd());
    const formattedData = formatFormData({...formData, isBusinessVerified: false});
    setDetails(formattedData);
    return details;
  };

  const closeErrorModal = () => {
    const formattedData = formatFormData(formDetails);
    setDetails(formattedData);
    setSubmitState(submissionStates.default);
    closeModal();
  };

  useEffect(() => {
    if (details.error) {

      openModal({
        title: 'Error',
        children: <ErrorModalSection
          traceId={details?.traceId}
          error={details?.error}
          onCloseErrorModal={closeErrorModal}
        />,
        type: 'error',
        onCloseModal: closeErrorModal
      });
      return;
    }
    if (submitState === submissionStates.submitting && !details.error) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div className={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);

            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>

                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <div className={styles.titleContainer}>
                  <div className={styles.titleHeading}>
                    Please verify your current business address
                  </div>
                  <div className={styles.titleSubHeading}>
                    Please provide the below details about your business address
                  </div>
                </div>
                <div style={FormFieldWrapper}>

                  <AddressDetailsComponent />

                </div>
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
