export const BusinessType = [
  {
    'label': 'Sole Trader',
    'value': 'Sole Proprietorship'
  },
  {
    'label': 'Private Company',
    'value': 'Private Company'
  },
  {
    'label': 'Closed Corporation',
    'value': 'Close Corporation'
  },
  {
    'label': 'Public Company',
    'value': 'Public Company'
  }
];