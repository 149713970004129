import {
  atom,
  selector,
  selectorFamily
} from 'recoil';
import * as vodaLend from '../api/vodaLend';
import {
  OPF_APPLICATION_PROGRESS_STATES,
  appStatuses,
  applicationProduct
} from '../constants';

const fetchState = async () => await vodaLend.getApplications();

const applicationsDefault = selector({
  key: 'applicationDetailsDefault',
  get: async () => await fetchState()
});

const applicationsState = atom({
  key: 'applicationsState',
  default: applicationsDefault
});

export const applications = selector({
  key: 'applications',
  get: async ({ get }) => await get(applicationsState),
  set: ({ set }, inputData) => {
    set(applicationsState, inputData);
  }
});

export const applicationsList = selector({
  key: 'applicationsList',
  get: async ({ get }) => {
    const apps = await get(applicationsState);
    return Array.isArray(apps?.applications) ? apps.applications.slice().sort(
      (a, b) => new Date(b.applicationSubmitDate || 0).getTime() - new Date(a.applicationSubmitDate || 0).getTime()
    ) : [];
  },
  set: ({ set }, data) => {

    set(applicationsState, data);
  }
});

export const applicationById = selectorFamily({
  key: 'applicationById',
  get: (appId) => async ({ get }) => (get(applicationsList) || []).find((f) => f.applicationId === appId)
});

export const applicationStatuses = selector({
  key: 'applicationStatuses',
  get: async ({ get }) => {

    const resp = await get(applicationsList);

    const statuses = (resp || []).reduce((accum, next) => {

      if (OPF_APPLICATION_PROGRESS_STATES.includes(next.applicationStatus)) {
        accum.inProcessApplication = accum.inProcessApplication + 1;
      }

      if (next.applicationStatus === appStatuses.QuoteAvailable || next.applicationStatus === appStatuses.AwaitingCustomerAssessment) {
        accum.isQuoteAvailable = true;
      }

      if (
        next.applicationStatus === appStatuses.Active
        && next.applicationProduct === applicationProduct.RCF
      ) {
        accum.isCreditFacilityCreated = true;
      }

      if (
        next.applicationStatus === appStatuses.Active
        && next.applicationProduct === applicationProduct.BTA
      ) {
        accum.isAdvanceActive = true;
      }

      if (!appStatuses[next.applicationStatus]) {
        accum.unknownStatus = true;
      }

      return accum;

    }, {
      inProcessApplication: 0,
      isQuoteAvailable: false,
      isCreditFacilityCreated: false,
      isAdvanceActive: false,
      canApply: true,
      unknownStatus: false
    });

    if (statuses.inProcessApplication > 0 || statuses.unknownStatus) {
      statuses.canApply = false;
    }

    return statuses;
  }
});
