import {
  FormikInput,
  FormikSelect,
  theme,
} from '@vfs-digital-channels/ns-react-components';
import {
  Field,
  Form,
  Formik
} from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { FormSubmitHelper, SquircleCheckBox, SupportPopup } from '../../../components';
import {
  BusinessType,
  FormContainer,
  FormField,
  FormFieldWrapper,
  IndustryTypes,
  closedCorpCompanyRegNumRegex,
  privateCompanyRegNumRegex,
  publicCompanyRegNumRegex,
  submissionStates,
  vatRegex
} from '../../../constants';
import {
  mapValues,
  replaceUndefined,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { businessDetailsState, businessDetailsSubmit, useIsMobile } from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';
import styles from './BusinessDetails.module.css';

export const businessDetailsValidationSchema = yup.object({
  businessType: yup
    .mixed()
    .test('businessType', 'Type of business is a required field', (item) => !!item?.value),
  name: yup
    .string()
    .when('businessType', {
      is: (businessType) => businessType?.value === 'Sole Proprietorship',
      then: () => yup.string().trim().min(1, 'Business Name must be a valid Business Name consisting of at least 1 characters'),
      otherwise: () => yup.string().trim().min(1, 'Business Name must be a valid Business Name consisting of at least 1 characters').required('Business Name is a required field')
    }),
  differentBusinessName: yup.boolean(),
  tradingAs: yup.string(),
  regNumber: yup
    .string()
    .when('businessType', {
      is: (businessType) => businessType?.value !== 'Sole Proprietorship',
      then: () => yup.string().required('Business Registration Number is a required field')
        .test((value, context) => {

          const regNumber = value;
          const { businessType } = context?.parent || {};
          const { createError } = context || {};

          if (businessType?.value === 'Private Company' && !privateCompanyRegNumRegex.test(regNumber)) {
            return createError({ message: 'Business Registration Number must be a valid numerical value in the format 2001/xxxxxx/07' });
          }

          if (businessType?.value === 'Close Corporation' && !closedCorpCompanyRegNumRegex.test(regNumber)) {
            return createError({ message: 'Business Registration Number must be a valid numerical value in the format 2001/xxxxxx/23' });
          }

          if (businessType?.value === 'Public Company' && !publicCompanyRegNumRegex.test(regNumber)) {
            return createError({ message: 'Business Registration Number must be a valid numerical value in the format 2001/xxxxxx/06' });
          }
          return true;
        }),
      otherwise: () => yup.string()
    }),
  vatNumber: yup
    .string()
    .when('businessType', {
      is: (businessType) => businessType?.value === 'Sole Proprietorship',
      then: () => yup
        .string()
        .required('VAT Number is a required field')
        .matches(vatRegex, 'VAT Number must be a valid numerical value in the format 40xxx56789'),
      otherwise: () => yup.string()
    }),
  industry: yup
    .mixed()
    .test('industry', 'Please select an option from drop down list', (item) => !!IndustryTypes.find((d) => d.value === item?.value))
    .required('What industry are you in is a required field')
});

export const BusinessDetails = () => {
  const { submitState, setSubmitState } = useOutletContext();
  const [details, setDetails] = useRecoilState(businessDetailsSubmit);
  const formRef = React.useRef();
  const isMobile = useRecoilValue(useIsMobile);
  const resetBusinessDetails = useResetRecoilState(businessDetailsState);
  const errorInSubmittedDetails = useFindErrorOnState([businessDetailsSubmit], () => {
    setSubmitState(submissionStates.default);
    resetBusinessDetails();
  });
  const formDetails = useMemo(() => replaceUndefined({
    ...details,
    tradingAs: details.tradingAs || '',
    businessType: BusinessType.find((b) => b.value === details.businessType),
    differentBusinessName: !!details.tradingAs,
    industry: IndustryTypes.find((i) => i.value === details.industry)
  }), [details]);
  const { track, events } = useTealium();
  const location = useLocation();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.businessDetails1.formStart());
  };

  useEffect(() => {
    track(events.businessDetails1.pageView());
  }, []);

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const formSubmit = (formData) => {
    track(events.businessDetails1.formEnd(formData.industry?.value));
    setDetails(formatFormData({...formData, isBusinessVerified: true}));
    return details;
  };

  useEffect(() => {
    if (submitState === submissionStates.submitting && !errorInSubmittedDetails?.contents) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [submitState, errorInSubmittedDetails]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div className={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validationSchema={businessDetailsValidationSchema}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ values, errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>

                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <div className={isMobile ? styles.mobileTitleContainer : styles.titleContainer}>
                  <div className={isMobile ? styles.mobileTitleHeading : styles.titleHeading}>
                    Tell us about your business
                  </div>
                  <div className={styles.titleSubHeading}>
                    Please provide the below details about your business
                  </div>
                </div>

                <div style={FormFieldWrapper}>
                  <div style={FormField}>
                    <FormikSelect
                      label='Type of business'
                      name='businessType'
                      options={BusinessType}
                      scrollToTop={false} />
                  </div>

                  <div style={FormField}>
                    <FormikInput label='Business name' name='name' />
                  </div>

                  <div className={styles.formFieldRadioContainer}>
                    <Field
                      name='differentBusinessName'
                      id='differentBusinessName_BusinessDetails'
                      label='My business is known by a different name'
                      component={SquircleCheckBox}
                      checked={values.differentBusinessName} />
                  </div>

                  {values.differentBusinessName && (
                    <div style={FormField}>
                      <FormikInput label='Trading Name (Optional)' name='tradingAs' />
                    </div>
                  )}

                  {values.businessType?.value !== 'Sole Proprietorship' && (
                    <div style={FormField}>
                      <FormikInput label='Business registration number' name='regNumber' />
                    </div>
                  )}

                  {values.businessType?.value === 'Sole Proprietorship' && (
                    <div style={FormField}>
                      <FormikInput label='VAT number' name='vatNumber' />
                    </div>
                  )}

                  <div style={{
                    ...FormField
                  }} className={styles.subTextMargin}
                  >

                    <FormikSelect
                      label='What industry are you in?'
                      name='industry'
                      subtext='Example: Restaurant, Bed & Breakfast, car hire etc'
                      options={IndustryTypes}
                    />
                  </div>
                </div>

              </Form>
            )
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
