import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ProfileRoutes } from '../../../Router';
import { Footer } from '../../../components';
import {
  Colors,
  Fonts
} from '../../../constants';
import {
  businessDetailsSubmit,
  useIsMobile,
  useIsSuperMobile
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  profileHomeContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontFamily: Fonts.Roboto,
    marginBottom: 35
  },
  myProfileFormBox: {
    marginTop: 70,
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    height: '100%',
    alignItems: 'center',
    marginBottom: 80
  },
  myProfileFormBoxMobile: {
    width: '90%',
    marginTop: 38
  },
  myProfileFormBoxSuperMobile: {
    width: '90%',
    marginTop: 50,
    maxWidth: 662
  },
  heading: {
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: -0.9,
    width: '100%'
  },
  headingMobile: {
    marginBottom: 18
  },
  subHeading: {
    color: Colors.GreyScale10,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    width: '100%',
    lineHeight: '140%',
    marginTop: 30,
    marginBottom: 10
  },
  userDetails: {
    display: 'flex',
    marginTop: 19,
    width: '100%',
    alignItems: 'center',
    height: 90,
    borderRadius: 20,
    justifyContent: 'center',
    border: `1px solid ${Colors.GreyScale5}`
  },
  userDetailsSuperMobile: {
    height: 70
  },
  userDetailsMobile: {
    height: 60,
    marginTop: 10,
    borderRadius: 8
  },
  userDetailsInner: {
    display: 'flex',
    alignItems: 'center',
    width: 519,
    justifyContent: 'space-between'
  },
  userDetailsInnerMobile: {
    width: '90%',
    maxWidth: 582
  },
  sectionType: {
    color: Colors.GreyScale12,
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: Fonts.RobotoMedium
  },
  sectionTypeSuperMobile: {
    fontSize: 18
  },
  sectionTypeMobile: {
    fontSize: 16
  },
  edit: {
    borderRadius: 8,
    display: 'flex',
    width: 150,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.GreyScale2,
    cursor: 'pointer',
    fontSize: 14,
    fontHeight: 500,
    lineHeight: '140%',
    fontFamily: Fonts.RobotoMedium
  },
  editSuperMobile: {
    width: 130,
    fontSize: 14
  },
  editMobile: {
    width: 100,
    fontSize: 14
  }
};

const ProfileSectionItems = {
  personalDetails: {
    title: 'Personal details',
    path: 'personalDetails'
  },
  personalAddress: {
    title: 'Address details',
    path: 'personalAddress'
  },
  businessDetails: {
    title: 'Business details',
    path: 'businessDetails'
  },
  businessAddress: {
    title: 'Business address details',
    path: 'businessAddress'
  },
  directorDetails: {
    title: 'Director details',
    path: 'directorDetails'
  },
  bankDetails: {
    title: 'Bank details',
    path: 'bankDetails'
  },
  documentDetails: {
    title: 'Documents',
    path: 'documentDetails'
  }
};

export const ProfileHome = () => {

  const isMobile = useRecoilValue(useIsMobile);
  const [profileItems, setProfileItems] = useState(ProfileSectionItems);
  const businessDetails = useRecoilValue(businessDetailsSubmit);
  const isSuperMobile = useRecoilValue(useIsSuperMobile);
  const navigate = useNavigate();
  const { track, events } = useTealium();

  useEffect(() => {
    track(events.myProfile.pageView());
  }, []);

  useEffect(() => {
    if (businessDetails && businessDetails?.businessType === 'Sole Proprietorship') {

      const filterDirectorsSection = Object.fromEntries(
        Object
          .entries(ProfileSectionItems)
          .filter(([key, _]) => key !== 'directorDetails')
      );
      setProfileItems(filterDirectorsSection);
    }
  }, [businessDetails]);

  const goToEditPage = (path,category) => {
    track(events.myProfile.editProfileUiInteraction(category));
    navigate(`${ProfileRoutes[path]}`);
  };

  const myProfileFormBox = useMemo(() => myProfileFormBoxStyle(isMobile, isSuperMobile), [isSuperMobile, isMobile]);
  const userDetails = useMemo(() => userDetailsStyle(isMobile, isSuperMobile), [isMobile, isSuperMobile]);
  const formType = useMemo(() => formTypeStyle(isMobile, isSuperMobile), [isMobile, isSuperMobile]);
  const editBtn = useMemo(() => editBtnStyle(isMobile, isSuperMobile), [isMobile, isSuperMobile]);
  const userDetailsInnerStyle = useMemo(() => ({ ...styles.userDetailsInner, ...(isSuperMobile ? styles.userDetailsInnerMobile : {}) }), [isSuperMobile]);
  const headingStyle = useMemo(() => ({ ...styles.heading, ...(isMobile ? styles.headingMobile : {}) }), [isMobile]);

  return (
    <div style={styles.profileHomeContainer}>
      <div style={myProfileFormBox}>
        <div style={headingStyle}>
          My profile
        </div>
        <div style={styles.subHeading}>
          Please note: Updates will not reflect on previously submitted applications.
        </div>
        {Object.values(profileItems).map((item, idx) => (
          <div style={userDetails} key={idx}>
            <div style={userDetailsInnerStyle}>
              <div style={formType}>
                {item.title}
              </div>
              <div
                style={editBtn}
                onKeyDown={() => goToEditPage(item.path,item.title)}
                onClick={() => goToEditPage(item.path,item.title)}
                role='presentation'
              >
                Edit
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer
        leftTitle={''}
        rightTitle={''}
      />
    </div>
  );
};

const userDetailsStyle = (isMobile, isSuperMobile) => {
  if (isSuperMobile && !isMobile) {
    return {
      ...styles.userDetails,
      ...styles.userDetailsSuperMobile
    };
  } else if (isMobile) {
    return {
      ...styles.userDetails,
      ...styles.userDetailsMobile
    };
  }
  return styles.userDetails;
};

const myProfileFormBoxStyle = (isMobile, isSuperMobile) => {
  if (isSuperMobile && !isMobile) {
    return {
      ...styles.myProfileFormBox,
      ...styles.myProfileFormBoxSuperMobile
    };
  } else if (isMobile) {
    return {
      ...styles.myProfileFormBox,
      ...styles.myProfileFormBoxMobile
    };
  }
  return styles.myProfileFormBox;
};

const editBtnStyle = (isMobile, isSuperMobile) => {
  if (isSuperMobile && !isMobile) {
    return {
      ...styles.edit,
      ...styles.editSuperMobile
    };
  } else if (isMobile) {
    return {
      ...styles.edit,
      ...styles.editMobile
    };
  }
  return styles.edit;
};

const formTypeStyle = (isMobile, isSuperMobile) => {
  if (isSuperMobile && !isMobile) {
    return {
      ...styles.sectionType,
      ...styles.sectionTypeSuperMobile
    };
  } else if (isMobile) {
    return {
      ...styles.sectionType,
      ...styles.sectionTypeMobile
    };
  }
  return styles.sectionType;
};
