import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationRoutes, StaticRoutes } from '../../Router';
import {
  ApplyHeader,
  FlatLinePager,
  Footer
} from '../../components';
import {
  ApplicationSteps,
  CommonHeight,
  submissionStates
} from '../../constants';
import {
  applicationStatuses,
  applicationsList,
  businessDetailsSubmit,
  useIsMobile
} from '../../recoil';

const ARRAY_LAST_IDX = -1;

const styles = {
  outletContainer: {
    width: '100%',
    marginBottom: CommonHeight.NavigationFooterHeightDesktop
  }
};

const stepKeysSorted = Object.keys(ApplicationSteps).filter(
  (s) => ApplicationSteps[s].paths?.length > 0
).sort(
  (a, b) => ApplicationSteps[a].stepNum - ApplicationSteps[b].stepNum
);
const firstStepNum = ApplicationSteps[stepKeysSorted[0]].stepNum;

const findStepKey = (url) => {
  return Object
    .keys(ApplicationSteps)
    .find((k) => ApplicationSteps[k].paths?.includes(url));
};

const findSubStepNum = (stepDetails, url) => stepDetails?.paths?.findIndex?.((p) => p === url) || ARRAY_LAST_IDX;

const findNextStep = (currentStepKey) => stepKeysSorted.find(
  (k) => ApplicationSteps[currentStepKey]?.stepNum < ApplicationSteps[k].stepNum
) || stepKeysSorted[0];

const findPrevStep = (currentStepKey) => stepKeysSorted.findLast(
  (k) => ApplicationSteps[currentStepKey]?.stepNum > ApplicationSteps[k].stepNum
) || stepKeysSorted.at(ARRAY_LAST_IDX);

const wizardSteps = Object.values(ApplicationSteps).map((s) => ({
  label: s.title,
  subSteps: s.paths?.length || 1
}));

export const LoanApplication = () => {

  const [applicationSteps, setApplicationSteps] = useState(ApplicationSteps);

  const businessDetails = useRecoilValue(businessDetailsSubmit);
  const appsList = useRecoilValue(applicationsList);
  const appStates = useRecoilValue(applicationStatuses);
  const isMobile = useRecoilValue(useIsMobile);

  const navigate = useNavigate();
  const location = useLocation();

  const [currentStepKey, setCurrentStepKey] = useState(findStepKey(location.pathname));

  const currentStep = useMemo(() => {
    let appSteps = ApplicationSteps;
    if (businessDetails?.businessType === 'Sole Proprietorship') {
      appSteps = {
        ...ApplicationSteps,
        businessDetails: {
          ...ApplicationSteps.businessDetails,
          paths: ApplicationSteps.businessDetails.paths.filter(d => d !== '/apply/businessDirectors')
        }
      };
    }
    setApplicationSteps(appSteps);

    let localStorageStepKey = localStorage.getItem('loanApplicationStepKey');

    if (!localStorageStepKey) {
      localStorageStepKey = 'personalDetails';

      localStorage.setItem('loanApplicationStepKey', localStorageStepKey);
    }

    if (appSteps[currentStepKey]?.stepNum > appSteps[localStorageStepKey]?.stepNum) {
      setCurrentStepKey(localStorageStepKey);
      return appSteps[localStorageStepKey];
    }
    return appSteps[currentStepKey];
  }, [currentStepKey, businessDetails]);

  const stepNum = useMemo(() => {
    if (applicationSteps) {
      return applicationSteps[findStepKey(location.pathname)]?.stepNum || 0;
    }
    return 0;
  }, [location.pathname, applicationSteps]);

  const numSubSteps = useMemo(() => {
    if (applicationSteps) {
      return applicationSteps[findStepKey(location.pathname)]?.paths?.length || 0;
    }
    return 0;
  }, [location.pathname, applicationSteps]);

  const [subStepNum, setSubStepNum] = useState(findSubStepNum(currentStep, location.pathname));

  const navPath = useMemo(() => currentStep?.paths?.[subStepNum] || '', [currentStep, subStepNum]);

  const [submitState, setSubmitState] = useState(submissionStates.default);

  const goToNextPage = () => {
    const sub = subStepNum + 1;
    const nextStep = findNextStep(currentStepKey);
    if (sub < numSubSteps) {
      setSubStepNum(sub);
    } else {
      setSubStepNum(0);
      setCurrentStepKey(nextStep);
      localStorage.setItem('loanApplicationStepKey', nextStep);
    }
  };

  const goToPreviousPage = () => {
    const sub = subStepNum - 1;
    const prevStep = findPrevStep(currentStepKey);

    if (sub < 0) {
      setCurrentStepKey(prevStep);
      setSubStepNum((applicationSteps[prevStep]?.paths?.length || 1) - 1);
      localStorage.setItem('loanApplicationStepKey', prevStep);
    } else {
      setSubStepNum(sub);
    }
  };

  const backClick = () => {
    goToPreviousPage();
  };

  const nextClick = () => {
    if (submitState === submissionStates.canSubmit) {
      setSubmitState(submissionStates.startSubmit);
      const loanApplicationStepKey = localStorage.getItem('loanApplicationStepKey');
      if (loanApplicationStepKey === 'fundingDetails') {
        localStorage.removeItem('loanApplicationStepKey');
      }
    }
  };

  useEffect(() => {

    if (navPath) {
      navigate(navPath);
    } else {
      goToNextPage();
    }
  }, [navPath]);

  useEffect(() => {
    if (submitState === submissionStates.submitSuccess) {
      goToNextPage();
    }
  }, [submitState]);

  useEffect(() => {
    if (Array.isArray(appsList) && appsList.length && !appStates?.canApply) {
      navigate(StaticRoutes.applications);
      return;
    }
    if (location.pathname === '/apply' || location.pathname === '/apply/') {
      navigate(ApplicationRoutes.personalDetails);
      return;
    }
  }, [location.pathname, appStates, appsList]);

  const subStepCurrentNum = useMemo(() => {
    if (applicationSteps) {
      const resp = findSubStepNum(applicationSteps[findStepKey(location.pathname)], location.pathname);
      if (resp === ARRAY_LAST_IDX) {
        return 0;
      }
      return resp;
    }
    return 0;
  }, [location.pathname, applicationSteps]);

  useEffect(() => {
    const loanApplicationStepKey = localStorage.getItem('loanApplicationStepKey');
    if (loanApplicationStepKey !== findStepKey(location.pathname)) {
      navigate(ApplicationRoutes[loanApplicationStepKey]); 
    }
  }, [location, navigate]);

  return (
    <>
      {currentStep && <ApplyHeader
        currentStep={stepNum}
        activeSubStep={subStepCurrentNum}
        steps={wizardSteps}
        onBackClick={backClick}
      />}

      <div style={styles.outletContainer}>
        <Outlet context={{ submitState, setSubmitState }} />

        {numSubSteps > 1 && <FlatLinePager numSteps={numSubSteps} activeStep={subStepCurrentNum} />}
      </div>

      <Footer
        leftTitle={(stepNum <= firstStepNum && subStepNum <= 0) || isMobile ? '' : 'Back'}
        rightTitle='Next'
        leftCallback={backClick}
        rightCallback={submitState === submissionStates.canSubmit ? nextClick : null}
      />
    </>
  );
};
