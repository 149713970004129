import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import { StaticRoutes } from '../../../Router';
import {
  DirectorDetailsComponent,
  Footer,
  SupportPopup,
  directorValidationSchema,
  validateDirectorEmails
} from '../../../components';
import {
  FormContainer,
  Gender,
  Position,
  Titles
} from '../../../constants';
import {
  mapValues,
  replaceUndefined,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import {
  businessDetailsState,
  businessDetailsSubmit,
  personalDetailsSubmit,
  toasts,
  useIsMobile,
  useShowToast
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '50vh',
    marginBottom: 100
  }
};

export const ProfileDirectorDetails = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const details = useRecoilValue(businessDetailsSubmit);
  const personaDetail = useRecoilValue(personalDetailsSubmit);
  const isMobile = useRecoilValue(useIsMobile);
  const formRef = useRef();
  const showToast = useShowToast();
  const resetToast = useResetRecoilState(toasts);
  const reset = useResetRecoilState(businessDetailsState);
  const { track, events } = useTealium();

  useFindErrorOnState([businessDetailsSubmit], () => {
    reset();
  });

  useEffect(() => {
    if (details && details?.businessType === 'Sole Proprietorship') {

      navigate(StaticRoutes.profile);
    }
  }, [details]);

  const detailsPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(businessDetailsSubmit, data);
    return await snapshot.getPromise(businessDetailsSubmit);
  }, []);

  const isSoleTrader = details.businessType === 'Sole Proprietorship';

  const formDetails = useMemo(() => {

    if (isSoleTrader) {

      const removeEmpty = replaceUndefined({
        ...details,
        directors: [personaDetail].map((d) => ({
          ...d,
          title: Titles.find((t) => t.value === d.title),
          gender: Gender.find((g) => g.value === d.gender),
          positionInCompany: Position.find((p) => p.value === d.positionInCompany)
        }))
      });

      return removeEmpty;
    }

    const removeEmpty = replaceUndefined({
      ...details,
      directors: !Array.isArray(details.directors) ? [] : details.directors.map((d) => ({
        ...d,
        title: Titles.find((t) => t.value === d.title),
        gender: Gender.find((g) => g.value === d.gender),
        positionInCompany: Position.find((p) => p.value === d.positionInCompany)
      }))
    });

    return removeEmpty;

  }, [details]);

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const submitHandler = async (formValues) => {
    const submission = formatFormData(formValues);
    await detailsPromise(submission);
    showToast('Updated successfully');
  };

  const goToMainPage = () => {
    track(events.myProfile.cancelProfileUiInteraction());
    navigate(StaticRoutes.profile);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      track(events.myProfile.saveProfileUiInteraction());
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (details.error) {

      resetToast();
      return;
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div style={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={submitHandler}
          validationSchema={directorValidationSchema}
          validateOnMount={true}
          innerRef={formRef}
          validate={validateDirectorEmails}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);

            return (
              <Form style={FormContainer}>

                <DirectorDetailsComponent
                  heading={'Director details'}
                  journeyType={'editProfile'}
                />

                <Footer
                  leftCallback={goToMainPage}
                  rightCallback={Object.values(errors).length ? null : handleSubmit}
                  leftTitle={isMobile ? '' : 'Cancel'}
                  rightTitle={'Save'}
                />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
