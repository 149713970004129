import {
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo
} from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import {
  DirectorDetailsComponent,
  ErrorModalSection,
  FormSubmitHelper,
  SupportPopup,
  directorValidationSchema,
  validateDirectorEmails
} from '../../../components';
import {
  CommonHeight,
  FormContainer,
  Gender,
  Position, Titles,
  submissionStates
} from '../../../constants';
import {
  mapValues,
  replaceUndefined,
  stripEmpty
} from '../../../helpers';
import {
  businessDetailsSubmit,
  personalDetailsSubmit
} from '../../../recoil';

import { useModal } from '../../../components/modalController/useModal';
import { findFirstValidationError } from '../../../helpers/formik';
import { useTealium } from '../../../tealium/useTealium';


export const BusinessDirectorDetails = () => {

  const [openModal, closeModal] = useModal();
  const { submitState, setSubmitState } = useOutletContext();
  const details = useRecoilValue(businessDetailsSubmit);
  const personaDetail = useRecoilValue(personalDetailsSubmit);
  const { track, events } = useTealium();
  const location = useLocation();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.businessDirectors.formStart());
  };

  useEffect(() => {
    track(events.businessDirectors.pageView());
  }, []);

  const detailsPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    track(events.businessDirectors.formEnd());
    set(businessDetailsSubmit, data);
    return await snapshot.getPromise(businessDetailsSubmit);
  }, []);

  const isSoleTrader = details.businessType === 'Sole Proprietorship';

  useEffect(() => {
    if (isSoleTrader) {
      setSubmitState(submissionStates.canSubmit);
    }
  }, [isSoleTrader, submitState]);

  const formDetails = useMemo(() => {

    if (isSoleTrader) {

      const removeEmpty = replaceUndefined({
        ...details,
        directors: [personaDetail].map((d) => ({
          ...d,
          title: Titles.find((t) => t.value === d.title),
          gender: Gender.find((g) => g.value === d.gender),
          positionInCompany: Position.find((p) => p.value === d.positionInCompany)
        }))
      });

      return removeEmpty;
    }

    const removeEmpty = replaceUndefined({
      ...details,
      directors: !Array.isArray(details.directors) ? [] : details.directors.map((d) => {
        if (d.email === personaDetail.email) {
          if (!d.positionInCompany) {
            d = {
              ...d,
              positionInCompany: personaDetail.positionInCompany
            };
          }
          if (!d.title) {
            d = {
              ...d,
              title: personaDetail.title
            };
          }
        }
        return {
          ...d,
          title: Titles.find((t) => t.value === d.title),
          gender: Gender.find((g) => g.value === d.gender),
          positionInCompany: Position.find((p) => p.value === d.positionInCompany)
        };
      })
    });

    return removeEmpty;

  }, [details]);

  const submitHandler = (formData) => detailsPromise(mapValues(stripEmpty({...formData, isBusinessVerified: false})));

  const closeErrorModal = () => {
    submitHandler(formDetails);
    setSubmitState(submissionStates.default);
    closeModal();
  };

  useEffect(() => {
    if (details.error) {

      openModal({
        title: 'Error',
        children: <ErrorModalSection
          traceId={details?.traceId}
          error={details?.error}
          onCloseErrorModal={closeErrorModal}
        />,
        type: 'error',
        onCloseModal: closeErrorModal
      });
      return;
    }
    if (submitState === submissionStates.submitting && !details.error) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (

    <div style={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={submitHandler}
          validationSchema={directorValidationSchema}
          validateOnMount={true}
          validate={validateDirectorEmails}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);

            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>
                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <DirectorDetailsComponent
                  heading={'Tell us about your directors'}
                  subHeading={'Tell us a bit more about the business directors to help us better \n understand your business.'}
                  journeyType={'onBoarding'}
                />

              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '50vh',
    margin: `0 0 ${CommonHeight.NavigationFooterHeightDesktop} 0`
  }
};
